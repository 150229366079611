import { BuscaIndice } from './funcionesPropias.js'




function IrAlCarro(vGlobales) {
  let ret = ''

  // Solo permitir si el carro tiene algo
  if (vGlobales.numeroCarrito > 0) {
    // Si el usuario está identificado, permitirlo Siempre (Sea mercado Abierto o Cerrado)
    if (vGlobales.emailUsuario !== '') {
      ret = '/CarroCompra'
    }
    else {
      // Solo permitirlo si el mercado del carro es abierto
      let i = BuscaIndice(vGlobales.mercados, 'name', vGlobales.mercadoActual)
      // Lo debiera encontrar siempre, pero por si acaso
      if (i >= 0) {
        // Si es Abierto
        if (vGlobales.mercados[i].abierto) {
          ret = '/CarroCompra'
        }
        else {
          ret = '/Identificarse'
        }
      }
    }
  }

  return ret
}


function IrAProductosMercado(vGlobales) {
  let ret = ''
  // Si hay mercado seleccionado
  if (vGlobales.mercadoActual !== '') {
    ret = '/MisProductos'
  }
  /*
  else if(vGlobales.intentoMercado !== ''){
    // Localizamos el mercado
    let indice = BuscaIndice(vGlobales.mercados, 'name', vGlobales.intentoMercado)
    if(indice === -1){
      ret = '/'
    } else {
      let mercado = vGlobales.mercados[indice]
      if(mercado.abierto) {
        ret = '/MisProductos'
      } else {
        if(vGlobales.emailUsuario === ""){
          ret = '/Identificarse'
        } else {
          ret = '/MisProductos'
        }
      }
    }
  }
  */
  else {
    if (vGlobales.emailUsuario === "") {
      ret = '/Identificarse'
    } else {
      ret = '/Mercados'
    }
  }
  return ret
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Obten el precio de venta en funcion de cliente sin iva (0), cliente con iva (1), cliente con iva y recargo (2)
// Recibe:
//    p = precio
//    i = tipo de iva del producto
//    u = tipo de usuario
function PrecioCliente(p, i, u) {
  // Reajustar el codigo de Iva a Iva
  let iva
  if (i === "001") {
    iva = 21
  } else if (i === "002") {
    iva = 10
  } else if (i === "003") {
    iva = 4
  } else if (i === "004") {
    iva = 0
  } else if (i === "005") {
    iva = 5
  } else if (i === "006") {
    iva = 2
  } else if (i === "007") {
    iva = 7.5
  }

  //
  p = Number(p)
  i = iva
  u = Number(u)


  let ret
  if (u === 0) {
    // Precio sin Iva
    ret = p
  }
  else if (u === 1) {
    // Precio con Iva
    ret = p * (1 + i / 100)
  }
  else if (u === 2) {
    // Precio con Iva y Recargo de Equivalencia
    if (i === 21) {
      i = 5.20 + i
    } else if (i === 10) {
      i = 1.40 + i
    } else if (i === 4) {
      i = 0.50 + i
    } else if (i === 5) {
      i = 0.60 + i
    } else if (i === 2) {
      i = 0.26 + i
    } else if (i === 7.5) {
      i = 1.00 + i
    }
    ret = p * (1 + i / 100)
  }


  // Redondea al numero de decimales que trajese
  let dec = 0
  let cadNum = "" + ret
  let pos = cadNum.indexOf('.')
  if (pos === -1) {
    dec = 0
  } else {
    dec = cadNum.substr(pos + 1).length
  }
  dec = dec >= 4 ? 4 : dec


  ret = parseFloat(ret)
  ret = Math.round(ret * Math.pow(10, dec)) / Math.pow(10, dec)


  return ret
}





///////////////////////////////////////
// Transformamos la cadena de ingredientes a formato legal
function ComponIngredientes(p) {
  // Compón la cadena de ingredientes
  let elementosTp = ""
  let elementos = []
  let elemento = ""
  let pLetra = ""

  if (p.length > 0) {
    elementosTp = p.split(",")
    elementosTp.forEach(function (e, i) {
      pLetra = e.trim().charAt(0)
      elemento = e.trim()
      elemento = (elemento.charAt(0)).toUpperCase() + (elemento.substr(1)).toLowerCase()
      elementos[i] = { "texto": elemento, "tipo": pLetra.toUpperCase() === pLetra ? "2" : "1" }
    })
  }
  return elementos

}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Localiza y devuelve las fechas de entrega posible del producto. Primero busca en el producto y si no existe busca en el mercado,
//    y si no existe tampoco el mercado mañana
//
// Recibe la lista completa de fechas de entrega ordenadas por producto+fecha y por mercado+fecha, el Id del producto, y el Nº de mercado
// Devuelve una fecha



// Elimina las fechas anteriores a los días que vengan en parámetro, y si no viene nada a hoy
function FiltraFechasEntrega(fechas, diasMinimos) {
  if (fechas !== undefined) {
    if (diasMinimos === undefined) {
      diasMinimos = 0
    }

    let hoy = new Date()
    let hoyMasDiasMinimos = new Date(hoy.getTime() + (24 * 60 * 60 * 1000 * diasMinimos))

    let fano = hoyMasDiasMinimos.getFullYear()
    let fmes = 1 + hoyMasDiasMinimos.getMonth()
    fmes = "" + fmes
    if (fmes.length < 2) {
      fmes = "0" + fmes
    }
    let fdia = hoyMasDiasMinimos.getDate()
    fdia = "" + fdia
    if (fdia.length < 2) {
      fdia = "0" + fdia
    }
    hoyMasDiasMinimos = fano + "-" + fmes + "-" + fdia
    return fechas.filter(fecha => fecha >= hoyMasDiasMinimos)
  } else {
    return fechas
  }

}




function ObtenFechasEntrega(fechasEntregaPro, fechasEntregaMer, productId, mercadoUsuario, diasMinimos) {

  // Añade a hoy el número de días mínimo días (va en milisegundos)
  let hoy = new Date()
  let hoyMasDiasMinimos = new Date(hoy.getTime() + (24 * 60 * 60 * 1000 * diasMinimos))

  let fano = hoyMasDiasMinimos.getFullYear()
  let fmes = 1 + hoyMasDiasMinimos.getMonth()
  fmes = "" + fmes
  if (fmes.length < 2) {
    fmes = "0" + fmes
  }
  let fdia = hoyMasDiasMinimos.getDate()
  fdia = "" + fdia
  if (fdia.length < 2) {
    fdia = "0" + fdia
  }
  hoyMasDiasMinimos = fano + "-" + fmes + "-" + fdia

  let fechas = []

  let encontrado = BuscaIndice(fechasEntregaPro, "product", productId)
  if (encontrado !== -1) {
    // Encontrado --> Hay calendarios del producto
    for (let i = encontrado; typeof fechasEntregaPro[i] !== 'undefined' && fechasEntregaPro[i].product === productId; i++) {
      if (fechasEntregaPro[i].fecha.substr(0, 10) >= hoyMasDiasMinimos) {
        fechas.push(fechasEntregaPro[i].fecha)
      }
    }
  }
  else {
    // No encontrado --> Hay calendarios del mercado
    encontrado = BuscaIndice(fechasEntregaMer, "mercado", mercadoUsuario)
    if (encontrado !== -1) {
      for (let i = encontrado; typeof fechasEntregaMer[i] !== 'undefined' && fechasEntregaMer[i].mercado === mercadoUsuario; i++) {
        if (fechasEntregaMer[i].fecha.substr(0, 10) >= hoyMasDiasMinimos) {
          fechas.push(fechasEntregaMer[i].fecha)
        }
      }
    }
    else {
      // Mañana
      hoyMasDiasMinimos = new Date(hoy.getTime() + (24 * 60 * 60 * 1000 * diasMinimos))
      fechas.push(hoyMasDiasMinimos)
    }
  }
  return fechas
}


// Devuelve únicamente de las fechas particulares de los productos que esten en el carro
function FiltraFechasProductosCarro(f, items) {
  let ret = []
  let tp
  items.map((item, i) => {
    tp = BuscaIndice(f, 'product', item[0])
    if (tp !== -1) {
      ret.push(f[tp])
    }
    return null
  })
  return ret
}



function OrdenComplejo(arrayDatos, ordenes) {
  return arrayDatos.sort((a, b) => {
    return sortMultiCompare(a, b, ordenes)
  })
}

function sortMultiCompare(a, b, sorts) {
  let select = sorts[0].select
  let order = sorts[0].order

  let a1 = a[select]
  let b1 = b[select]
  if (select === 'description') {
    a1 = ExtractaParteDescripcion(a1)
    b1 = ExtractaParteDescripcion(b1)
  }
  if (a1 < b1) {
    return order === 'ascending' ? -1 : 1
  }
  if (a1 > b1) {
    return order === 'ascending' ? 1 : -1
  }
  if (sorts.length > 1) {
    let remainingSorts = sorts.slice(1)
    return sortMultiCompare(a, b, remainingSorts)
  }
  return 0
}

function ExtractaParteDescripcion(des) {
  let retorno = des.trim()
  let i = retorno.indexOf('.')
  if (i !== -1) {
    retorno = retorno.substr(0, i)
  }
  return retorno
}


function OrdenaProximidadLocalidad(lista, localidad) {
  let listaLoc = []

  //let tp = DistanciaKm(lugarAcceso, {la: localidad.latitud, lo: localidad.longitud})

  return listaLoc
}


function TextoUnidad(uni) {
  let unidades = ["Uni", "Kgr", "Lit"]
  if (uni < 0 || uni > 2) { uni = 0 }
  return unidades[uni]
}

export {
  IrAlCarro,
  IrAProductosMercado,
  PrecioCliente,
  ComponIngredientes,
  ObtenFechasEntrega,
  FiltraFechasEntrega,
  FiltraFechasProductosCarro,
  ExtractaParteDescripcion,
  OrdenComplejo,
  OrdenaProximidadLocalidad,
  TextoUnidad
}
