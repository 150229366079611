import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
//import { BorderBottom } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';

import { listaFamiliasClientes } from './../../apis/api-familia.js'
import { listaPueblos } from './../../apis/api-pueblo.js'
import { listaMercadosClientes } from './../../apis/api-mercado.js'

import BotonMercado from "../../components/botones/BotonMercado.js";
import BotonMisMercados from './../../components/botones/BotonMisMercados'
import BotonSinSalto from './../../components/botones/BotonSinSalto'
import ThemeGeroa from './../../components/ThemeGeroa.js'

import config from './../../config/config'
import { BuscaIndice, DistanciaKm, OrdenaArray, OrdenaArray2Campos } from './../../config/funcionesPropias.js'

import geolocation from 'geolocation'
//import {APIProvider} from '@vis.gl/react-google-maps';

/*
// Para poner un tamaño fijo al selector de Familias. //
// Comentado porque se ajusta al tamaño del cuero. //
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
*/

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '200px',
    minHeight: '65vh'
  },
  formLocalidades: {
    margin: theme.spacing(0),
    marginTop: 0,
    minWidth: 350,
    maxWidth: 400,
  },
  formMultiplesFamilias: {
    margin: theme.spacing(0),
    marginTop: 0,
    minWidth: 350,
    maxWidth: 400,
  },
  formFiltroFamilias: {
    margin: theme.spacing(0),
    marginTop: 0,
    marginLeft: 30,
    minWidth: 250,
    maxWidth: 300,
  },
  botonFiltrarFamilias: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 200,
    textTransform: "none",
    fontSize: "14px",
    [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
    padding: "0px 10px 0px 10px !important",
    [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
    fontWeight: "400",
    color: "#FFF",
    backgroundColor: ThemeGeroa.palette.primary.main,
    maxHeight: '30px',
    minWidth: '90px',
    minHeight: '30px',
    "&:hover,&:focus": {
      color: "#FFFFFF",
      backgroundColor: ThemeGeroa.palette.secondary.light,
      cursor: "hand",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    "&:disabled": {
      backgroundColor: ThemeGeroa.palette.secondary.dark,
    },
  },
  botonBorrarFiltrarFamilias: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    textTransform: "none",
    fontSize: "14px",
    [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
    padding: "0px 10px 0px 10px !important",
    [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
    fontWeight: "400",
    color: "#FFF",
    backgroundColor: ThemeGeroa.palette.primary.main,
    maxHeight: '30px',
    minWidth: '140px',
    minHeight: '30px',
    "&:hover,&:focus": {
      color: "#FFFFFF",
      backgroundColor: ThemeGeroa.palette.secondary.light,
      cursor: "hand",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    "&:disabled": {
      backgroundColor: ThemeGeroa.palette.secondary.dark,
    },
  },
  botonBorrarFiltrosFamilias: {
    marginTop: 15,
    marginBottom: 10,
    marginLeft: 20,
    textTransform: "none",
    fontSize: "14px",
    [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
    padding: "0px 10px 0px 10px !important",
    [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
    fontWeight: "400",
    color: "#FFF",
    backgroundColor: ThemeGeroa.palette.primary.main,
    maxHeight: '30px',
    minWidth: '200px',
    minHeight: '30px',
    "&:hover,&:focus": {
      color: "#FFFFFF",
      backgroundColor: ThemeGeroa.palette.secondary.light,
      cursor: "hand",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    "&:disabled": {
      backgroundColor: ThemeGeroa.palette.secondary.dark,
    },
  },
  textoInicio: {
    textDecoration: "none",
    color: "#026747",
    "&:hover": {
      color: ThemeGeroa.palette.secondary.main,
    },
  },
  botonMisMercados: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 100,
  },
}));


const Mercados = ({ t, ...props }) => {
  const classes = useStyles();
  const [errorDinahosting, setErrorDinahosting] = useState(false)
  const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

  const [datos, setDatos] = useState({
    mercados: [],
    listaLocalidades: [],
    listaFamilias: [],
    lugarAcceso: []
  })



  // Selector de Localidad. Si undefined poner "" para que no de error Select. //
  const [localidad, setLocalidad] = useState(valuesGlobales.localidadBusqueda || "")


  // Selectores de Filtro y Familias. //
  // Si se viene de eneek corderos --> filtro corderos
  //const [familia, setFamilia] = useState(props.origen === "Eneek" ? 2 : "")

  // Este filtro ya no se usa. //
  //const filtrosFamilias = [t("Mercados.TipoFiltroFamilias1"), t("Mercados.TipoFiltroFamilias2")]

  const [tipoFiltroFamilia, setTipoFiltroFamilia] = useState(0)
  const [familiaNombre, setFamiliaNombre] = useState([])
  const [filtroAbierto, setFiltroAbierto] = useState(false)


  // ------------------------------------------------------------ //
  // Documentación: https://es.reactjs.org/docs/hooks-effect.html //
  // ------------------------------------------------------------ //
  useEffect(() => {

    // Cargar Datos. //
    loadMercados()

    // El array vacío indica que sólo se se llama a useEffect 1 vez.
    // https://es.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    // Recargar cuando el usuario se identifica (cambia emailUsuario)
    // Así se cargarán los datos de Mercados del Usuario.

    // Para que ignore el error: React Hook useEffect has a missing dependency: 'loadMercados'
    // eslint-disable-next-line
  }, [valuesGlobales.emailUsuario])

  async function loadMercados() {
    // Guarda la pantalla en la que estamos
    // No hacer con setValuesGlobales porque no
    // actualiza los mercados que se cargan luego.
    //setValuesGlobales({ ...valuesGlobales, paginaOrigen: '/'})
    valuesGlobales.paginaOrigen = '/Mercados'

    // Geolocalizacion
    let localiz = { la: 0, lo: 0 }
    await geolocation.getCurrentPosition(function (err, position) {
      if (err) {
        localiz = { la: 0, lo: 0 }
      }
      else {
        localiz = { la: position.coords.latitude, lo: position.coords.longitude }
        //setLocalidad(valuesGlobales.localidadBusqueda || "0")
        setLocalidad(valuesGlobales.localidadBusqueda || "")
      }
    })
    try {
      ///////////////////////////////////////////////////////////////
      // Leer los Pueblos -- Localidades (Ordenado Alfabéticamente)//
      ///////////////////////////////////////////////////////////////
      let dataP = await listaPueblos()
      if (localiz.la !== 0) {
        dataP.splice(0, 0, { nombre: "ProximosAMi" })
      }
      //////////////////////////////////////////
      // Leer las Familias de los Productos.  //
      //////////////////////////////////////////
      const dataF = await listaFamiliasClientes()
      let tff = 0
      if (props.origen === "EneekCorderos") {
        // Busca la familia de los Corderos
        let tp = BuscaIndice(dataF, 'nombre', config.filtroEneekCorderos)
        if (tp !== -1) {
          familiaNombre.push(dataF[tp].nombre)
          tff = 1
          setTipoFiltroFamilia(1)
        }
      }

      ////////////////////////////////
      // Leer las Tarifas-Mercados. //
      ////////////////////////////////
      const dataM = await listaMercadosClientes()
      // cambio con la version actual en mysql -- let activos = dataM.filter((mercado) => mercado.activo && mercado.mostrarSiempre)
      let activos = dataM.filter((mercado) => { return mercado.activo && mercado.mostrarSiempre })
      // Si estamos ya identificados, añadir //
      // al array de activos mis mercados.   //
      if (valuesGlobales.emailUsuario !== '') {
        valuesGlobales.mercados.map(m => {
          if (!activos.find(mer => mer.name === m.name)) {
            activos.push(m)
          }
          return null
        })
      }

      // Ordena los Mercados
      CalculaDistanciasMercados(activos, localiz, 0)
      let listaMercados = OrdenaArray(activos, "description")
      // Actualizar values globales para que lo pinte. //
      valuesGlobales.mercados = listaMercados
      // Actualizar poblaciones. //
      valuesGlobales.poblaciones = dataP
      // Esto no lo actualiza sincronamente. //
      setValuesGlobales({ ...valuesGlobales, mercados: listaMercados, poblaciones: dataP })

      // Guarda los mercados en localStorage. Para que refresque en productos.
      localStorage.setItem('mercados', JSON.stringify(listaMercados))

      // Aplica el filtro a los mercados a ver
      listaMercados = filtraMercados(tff)
      //setMercados(listaMercados)
      datos.mercados = listaMercados
      //setListaLocalidades(dataP)
      datos.listaLocalidades = dataP
      //setLugarAcceso(localiz)
      datos.lugarAcceso = localiz
      //setListaFamilias(dataF)
      datos.listaFamilias = dataF
      //////////////////////
      // Forzar Repintado //
      //////////////////////
      setDatos({ ...datos, lugarAcceso: localiz })

    } catch (error) {
      // Pintar Venta de que Contacte con el Administrador -- Dinahosting MAL
      setErrorDinahosting(true)
      console.error("Error. Contactar con el administrador", error)
    }
  }



  const irAIdentificarse = () => {
    // Ir a Identificarse, eliminar los puntos y mercados del mapa
    setValuesGlobales({
      ...valuesGlobales,
      paginaOrigen: "/MisMercados",
      mercadosMapa: [],
      punto: ""
    })
    return true
  }




  const handleCloseDialogError = () => {
    var win = window.open("about:blank", "_self");
    win.close();
  }


  const handleChangeLocalidad = (event) => {
    let origen
    // Filtra los mercados de dicha localidad
    if (event.target.value === 0 && datos.lugarAcceso.la !== 0) {
      origen = {
        la: datos.lugarAcceso.la,
        lo: datos.lugarAcceso.lo
      }
    }
    else {
      origen = {
        la: datos.listaLocalidades[event.target.value].latitud,
        lo: datos.listaLocalidades[event.target.value].longitud
      }
    }

    //
    valuesGlobales.localidadBusqueda = event.target.value
    setLocalidad(event.target.value)

    //CalculaDistanciasMercados(mercados, origen)
    CalculaDistanciasMercados(datos.mercados, origen, event.target.value)
  }

  // Este filtro ya no se Usa. //
  /*
  const handleChangeFiltroFamilia = (event) => {
    //if(event.target.value === 0){
    // Vaciar y poner todas las familiar
    desmarcarFamiliasMarcadas()
    //}
    setTipoFiltroFamilia(event.target.value)
  }
  */


  const handleChangeFamilia = (event) => {
    // Cambia las familias visibles en función del filtro de familias
    if (event.target.value[event.target.value.length - 1] !== undefined) {
      setFamiliaNombre(event.target.value)
    }
  }




  const filtraMercadosPorFamilia = (event) => {
    // Cerrar la ventana de familias
    event.preventDefault()
    event.stopPropagation()
    setFiltroAbierto(false)
    if (familiaNombre.length === 0) {
      setDatos({ ...datos, mercados: valuesGlobales.mercados })
      setTipoFiltroFamilia(0)
    } else {
      let m = filtraMercados(tipoFiltroFamilia)
      setDatos({ ...datos, mercados: m })
    }
  }


  const borraFiltrosMercadosPorFamilia = (event) => {
    // Cerrar la ventana de familias
    event.preventDefault()
    event.stopPropagation()
    setFiltroAbierto(false)
    desmarcarFamiliasMarcadas()
  }



  const borraFiltrosMercadosPorMunicipio = (event) => {
    let origen
    // Filtra los mercados de dicha localidad
    if (datos.lugarAcceso.la !== 0) {
      origen = {
        la: datos.lugarAcceso.la,
        lo: datos.lugarAcceso.lo
      }
    }
    else {
      origen = {
        la: datos.listaLocalidades[event.target.value].latitud,
        lo: datos.listaLocalidades[event.target.value].longitud
      }
    }
    //CalculaDistanciasMercados(mercados, origen)
    CalculaDistanciasMercados(datos.mercados, origen, 0)
    //
    valuesGlobales.localidadBusqueda = event.target.value
    setLocalidad(0)
  }


  function filtraMercados(tff) {
    // Ahora: Todas las Seleccionadas. //
    let mercadosFiltrados = valuesGlobales.mercados.filter(mercado => {
      let sirve
      let busqueda
      // Si el filtro es AND, el mercado debe tener todas las familias
      if (tff === 0) {
        sirve = true
        for (let j = 0; j < familiaNombre.length; ++j) {
          busqueda = mercado.familias.indexOf(familiaNombre[j])
          if (busqueda === -1) {
            sirve = false
            break
          }
        }
      }
      // Si el filtro es OR, el mercado debe tener alguna familia
      else {
        sirve = false
        for (let j = 0; j < familiaNombre.length; ++j) {
          busqueda = mercado.familias.indexOf(familiaNombre[j])
          if (busqueda !== -1) {
            sirve = true
            break
          }
        }
      }
      // Añade o no el mercado. //
      return sirve
    })

    // Devuelve los filtrados. //
    return (mercadosFiltrados)
  }


  function desmarcarFamiliasMarcadas() {
    setFamiliaNombre([])
    setDatos({ ...datos, mercados: valuesGlobales.mercados })
  }



  function CalculaDistanciasMercados(listaMer, origen, loc) {
    if (loc !== 0) {
      //let mercadosOrdenados = []
      let distancia
      // Punto Origen Seleccion
      listaMer.map((mercado, i) => {
        mercado.cercania = 999999
        // Localidades
        mercado.localidades.map((localidad, j) => {
          distancia = DistanciaKm(origen, { la: localidad.latitud, lo: localidad.longitud })
          // Añadir la cercanía
          if (mercado.cercania > distancia) {
            mercado.cercania = distancia
          }
          return null
        })

        // Puntos de Recogida
        mercado.puntosDeRecogida.map((punto, j) => {
          distancia = DistanciaKm(origen, { la: punto.localidad.latitud, lo: punto.localidad.longitud })
          // Añadir la cercanía
          if (mercado.cercania > distancia) {
            mercado.cercania = distancia
          }
          return null
        })

        return null
      })
      // Gancho con los mercados ordenados por cercanía. //
      setDatos({ ...datos, mercados: OrdenaArray2Campos(listaMer, "cercania", "orden") })
    } else {
      // Ordena alfabeticamente

      setDatos({ ...datos, mercados: OrdenaArray(listaMer, "description") })

    }
  }


  //to={valuesGlobales.emailUsuario !== "" ? "/MisProductos" : "/Identificarse"}>

  function ponSalto(m) {
    /*
    let s = ''
    if(m.abierto){
      s = "/MisProductos"
    } else if(valuesGlobales.nombreUsuario === ''){
      s = "/Identificarse"
    } else{
      s = "/MisProductos"
    }
    return s
    */
    // esto es lo mismo en 2 instrucciones
    /*
    if (m.abierto || valuesGlobales.nombreUsuario !== '') return "/MisProductos"
    return "/Identificarse"
    */
    // esto es lo mismo en 1 instrucción: Si abierto o identificado ... //
    return (m.abierto || valuesGlobales.nombreUsuario !== '') ? "/MisProductos" : "/Identificarse"
  }

  function ponDesactivado(m) {
    /*
    let s = true
    if(valuesGlobales.mercadoActual === undefined || valuesGlobales.mercadoActual === '' || valuesGlobales.mercadoActual === m.name){
      s = false
    }
    return s
    */
    // esto es lo mismo en 1 instrucción: Desactiva si no hay mercadoActual o es distinto al actual. //
    return !(valuesGlobales.mercadoActual === undefined ||
      valuesGlobales.mercadoActual === '' ||
      valuesGlobales.mercadoActual === m.name)
  }

  // Ver si podemos entrar en el mercado. //
  const valida = (intentoMercado) => {
    //let index = BuscaIndice(valuesGlobales.mercados, 'name', valuesGlobales.intentoMercado)
    let index = BuscaIndice(valuesGlobales.mercados, 'name', intentoMercado)
    // Permite acceder si es:
    //   -- Mercado Abierto
    //   -- mercado Cerrado y pertenezco a él
    //let retorno = true
    let retorno = (index >= 0)
    if (index >= 0 && valuesGlobales.mercados[index].abierto === false) {
      if (valuesGlobales.emailUsuario !== "") {
        //if(valuesGlobales.mercadosUsuario.indexOf(valuesGlobales.intentoMercado) === -1){
        if (valuesGlobales.mercadosUsuario.indexOf(intentoMercado) === -1) {
          retorno = false
        }
      }
    }
    return retorno
  }

  // Clave aleatoria. para cambiar DOM
  let clave = Math.round(Math.random() * 100);


  // Por si hay error de Conexion a Dinahosting
  const errorConexionDinahosting = (
    errorDinahosting ?
      <Dialog
        open={true}
        //onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Mercados.TituloError")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Mercados.TextoError")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BotonSinSalto
            texto={t("Mercados.BotonError")}
            accion={handleCloseDialogError}
          />
        </DialogActions>
      </Dialog>
      :
      <div />
  )




  const busquedaLocalidades = (
    <Grid>
      <FormControl className={classes.formLocalidades}>
        <InputLabel id="IL-localidad">
          {t("Mercados.Localidades")}
        </InputLabel>
        {datos.listaLocalidades.length > 0 &&
          <Select
            labelId="select-localidad"
            id="select-localidad"
            name="select-localidad"
            value={localidad}
            onChange={handleChangeLocalidad}
          >
            {datos.listaLocalidades.map((localidad, i) => (
              <MenuItem key={"Loc" + i} value={i}>
                {localidad.nombre === "ProximosAMi" ? t("Mercados.ProximosAMi") : localidad.nombre}
              </MenuItem>
            ))}
          </Select>
        }
      </FormControl>
      <Button className={classes.botonBorrarFiltrosFamilias}
        onClick={borraFiltrosMercadosPorMunicipio}
      >
        {t("Mercados.BotonQuitarFiltrosMunicipios")}
      </Button>
    </Grid>
  )

  const clickDialog = (event) => {
    if (!filtroAbierto) {
      // Está cerrado Abre el Dialog
      setFiltroAbierto(true)
    } else {
      if (event.target.value === undefined) {
        setFiltroAbierto(false)

        let m = filtraMercados(tipoFiltroFamilia)
        setDatos({ ...datos, mercados: m })

      } else {

      }
    }
  }

  const busquedaFamilias = (
    <Grid>
      <FormControl className={classes.formMultiplesFamilias}
        onClick={clickDialog}
        onFocus={event => event.stopPropagation()}
      >
        <InputLabel id="IL-Familias">
          {t("Mercados.TipoBusquedaFamilias")}
        </InputLabel>
        <Select
          open={filtroAbierto}
          labelId="familias"
          id="familias-multiple-checkbox"
          name="familias-multiple-checkbox"
          multiple
          value={familiaNombre}
          onChange={handleChangeFamilia}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          disabled={true}
        >
          <Button className={classes.botonBorrarFiltrarFamilias}
            onClick={borraFiltrosMercadosPorFamilia}
          >
            {t("Mercados.BotonQuitarFiltrosProductos")}
          </Button>
          <Button className={classes.botonFiltrarFamilias}
            onClick={filtraMercadosPorFamilia}
          >
            {t("Mercados.BotonFiltroFiltrar")}
          </Button>

          {datos.listaFamilias.map((f, i) => (
            <MenuItem key={'familia' + i} value={f.nombre}>
              <Checkbox id={"fchk" + i} checked={familiaNombre.indexOf(f.nombre) > -1} />
              <ListItemText primary={f.nombre} />
            </MenuItem>
          ))}
          <Button className={classes.botonBorrarFiltrarFamilias}
            onClick={borraFiltrosMercadosPorFamilia}
          >
            {t("Mercados.BotonQuitarFiltrosProductos")}
          </Button>
          <Button className={classes.botonFiltrarFamilias}
            onClick={filtraMercadosPorFamilia}
          >
            {t("Mercados.BotonFiltroFiltrar")}
          </Button>
        </Select>

      </FormControl>
      <Button className={classes.botonBorrarFiltrosFamilias}
        onClick={borraFiltrosMercadosPorFamilia}
      >
        {t("Mercados.BotonQuitarFiltrosProductos")}
      </Button>


    </Grid>
  )


  const filtros = (
    <Grid container>
      <Grid item>
        {busquedaLocalidades}
        {busquedaFamilias}
      </Grid>
      <Grid item>
        <BotonMisMercados
          texto={t("Mercados.MisMercados")}
          salto={valuesGlobales.emailUsuario !== "" ? "/MisMercados" : "/Identificarse"}
          validacion={irAIdentificarse}
        />
      </Grid>
    </Grid>
  )


  return (
    <MuiThemeProvider theme={ThemeGeroa}>
      <Grid>
        <br />
        <Container maxWidth="md" className={classes.registro}>
          {errorConexionDinahosting}
          <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600 }} display="inline">
            {t("Mercados.Mercados")}
          </Typography>

          <Divider />
          <Typography style={{ color: ThemeGeroa.palette.gris.main, fontSize: 12, fontWeight: 400 }} display="inline">
            {t("Mercados.Texto")}
          </Typography>
          <Divider />
          <br />
          {filtros}
          <br />
          {datos.mercados.length !== 0 && (datos.lugarAcceso.la !== 0 || localidad !== undefined) ?
            datos.mercados.map((mercado, i) => (
              <Grid item key={"Mercado" + i}>
                <BotonMercado
                  key={clave + mercado.name + i}
                  mercado={mercado}
                  validacion={valida}
                  desactivado={ponDesactivado(mercado)}
                  salto={ponSalto(mercado)}
                />
                <br />
              </Grid>
            ))
            :
            <Fragment />
          }
        </Container>
        <br />
      </Grid>
    </MuiThemeProvider>
  );
}

export default withTranslation()(Mercados)